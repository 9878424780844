// src/DiscordRedirect.js
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const DiscordRedirect = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get("code");
        const error = urlParams.get("error");
        console.log(code, error);

        if (code) {
            window.opener.postMessage({ type: "discord-auth-code", code }, window.location.origin);
        } else if (error) {
            window.opener.postMessage({ type: "discord-auth-error" }, window.location.origin);
        }
        window.close();
    }, [navigate]);

    return <div>Redirecting...</div>;
};

export default DiscordRedirect;
