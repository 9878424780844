import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { jwtDecode } from "jwt-decode";

const RequireAuth = ({ allowedPermissions }) => {
    const { auth } = useAuth();
    const location = useLocation();
    allowedPermissions.push("*");

    const decoded = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
    const permissions = decoded?.permissions || [];

    return permissions.find((permission) => allowedPermissions.includes(permission)) ? (
        <Outlet />
    ) : auth?.accessToken ? (
        <Navigate
            to="/unauthorized"
            state={{ from: location.pathname }}
            replace
        />
    ) : (
        <Navigate
            to="/login"
            state={{ from: location.pathname }}
            replace
        />
    );
};

export default RequireAuth;
