import { RefreshToken } from "../services/authService";
import useAuth from "./useAuth";

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const refresh = async () => {
        const response = await RefreshToken();
        setAuth((prev) => {
            return { ...prev, accessToken: response.data.accessToken };
        });
        return response;
    };
    return refresh;
};

export default useRefreshToken;
