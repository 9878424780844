import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./app/queryClient";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./config/i18n";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "./context/AuthProvider";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ThemeProvider } from "./context/ThemeContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    // <React.StrictMode>
    <AuthProvider>
        <ThemeProvider>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <Routes>
                        <Route
                            path="/*"
                            element={<App />}
                        />
                    </Routes>
                </BrowserRouter>
                <ReactQueryDevtools />
            </QueryClientProvider>
        </ThemeProvider>
    </AuthProvider>
    // </React.StrictMode>
);
