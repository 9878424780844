import styled from "styled-components";

export const HomeContainer = styled.div`
    .title-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 82vh;
        .title {
            font-weight: 800;
            font-size: 50px;
        }
        .subtitle {
            font-weight: 900;
            font-size: 20px;
        }
        .title,
        .subtitle {
            text-align: center;
            padding: 10px;
            color: white;
            animation: fade-in 2s;
        }
    }
    .button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -25vh;
        button {
            font-size: 20px;
            margin: 0;
            color: white;
            background-color: #010f27;
            outline: 2px solid #00367c;
            width: 200px;
            height: 50px;
            border-radius: 5px;
            border: None;
            transition: background-color 0.3s, outline 0.3s, box-shadow 0.3s;
            animation: fade-in 2s;
            &:hover {
                cursor: pointer;
                background-color: rgb(8, 9, 22);
                outline: 2px solid #003f91;
                box-shadow: 0 0 10px 2px rgba(0, 162, 255, 0.7);
            }
            &:first-of-type {
                margin-right: 50px;
            }
            &:last-of-type {
                margin-left: 50px;
            }
        }
    }
`;
