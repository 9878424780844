import { useState } from "react";
import { EmbedContainer } from "../styles/EmbedbuilderStyle";

const EmbedBuilder = ({ isOpen, onClose, onSave, defaultEmbed }) => {
    const [embed, setEmbed] = useState(
        defaultEmbed || {
            title: "title",
            description: "description",
            color: "#f0f0f0",
            timestamp: new Date(),
            author: {
                name: "author",
                icon_url: "",
            },
        }
    );
    return <EmbedContainer></EmbedContainer>;
};

export default EmbedBuilder;
