import React from "react";
import { SimplePageContainer } from "../../styles/simplePageStyle";

const TermsOfService = () => {
    return (
        <SimplePageContainer>
            <span className="header">Terms of Service</span>
            <span className="content">
                <span className="section">
                    <span className="section-header">1. Terms</span>
                    <span className="section-content">
                        By accessing the website at https://servertool.xyz or using the Discord app,
                        you agree to be bound by these terms of service, all applicable laws, and
                        regulations, and accept responsibility for compliance with any applicable
                        local laws. If you disagree with any of these terms, you are prohibited from
                        using or accessing this site. The materials on this website are protected by
                        applicable copyright and trademark law.
                    </span>
                </span>
                <span className="section">
                    <span className="section-header">2. Use License</span>
                    <span className="section-content">
                        Permission is granted to temporarily download one copy of the materials
                        (information or software) on Server Tool's website for personal,
                        non-commercial transitory viewing only. This is the grant of a license, not
                        a transfer of title. Under this license, you may not:
                        <ul>
                            <li>Modify or copy the materials</li>
                            <li>
                                Use the materials for any commercial purpose or public display
                                (commercial or non-commercial)
                            </li>
                            <li>
                                Attempt to decompile or reverse engineer any software contained on
                                Server Tool's website or Discord app
                            </li>
                            <li>
                                Remove any copyright or other proprietary notations from the
                                materials
                            </li>
                            <li>
                                Transfer the materials to another person or "mirror" the materials
                                on any other server
                            </li>
                        </ul>
                        This license shall automatically terminate if you violate any of these
                        restrictions and may be terminated by Server Tool at any time. Upon
                        terminating your viewing of these materials or upon the termination of this
                        license, you must destroy any downloaded materials in your possession
                        whether in electronic or printed format.
                    </span>
                </span>
                <span className="section">
                    <span className="section-header">3. Disclaimer</span>
                    <span className="section-content">
                        The materials on Server Tool's website and Discord app are provided on an
                        'as is' basis. Server Tool makes no warranties, expressed or implied, and
                        hereby disclaims and negates all other warranties including, without
                        limitation, implied warranties or conditions of merchantability, fitness for
                        a particular purpose, or non-infringement of intellectual property or other
                        violation of rights.
                    </span>
                    <span className="section-content">
                        Further, Server Tool does not warrant or make any representations concerning
                        the accuracy, likely results, or reliability of the use of the materials on
                        its website or Discord app or otherwise relating to such materials or on any
                        sites linked to this site.
                    </span>
                </span>
                <span className="section">
                    <span className="section-header">4. Limitations</span>
                    <span className="section-content">
                        In no event shall Server Tool or its suppliers be liable for any damages
                        (including, without limitation, damages for loss of data or profit, or due
                        to business interruption) arising out of the use or inability to use the
                        materials on Server Tool's website or Discord app, even if Server Tool or a
                        Server Tool authorized representative has been notified orally or in writing
                        of the possibility of such damage. Because some jurisdictions do not allow
                        limitations on implied warranties, or limitations of liability for
                        consequential or incidental damages, these limitations may not apply to you.
                    </span>
                </span>
                <span className="section">
                    <span className="section-header">5. Accuracy of materials</span>
                    <span className="section-content">
                        The materials appearing on Server Tool's website could include technical,
                        typographical, or photographic errors. Server Tool does not warrant that any
                        of the materials on its website are accurate, complete, or current. Server
                        Tool may make changes to the materials contained on its website at any time
                        without notice. However, Server Tool does not make any commitment to update
                        the materials.
                    </span>
                </span>
                <span className="section">
                    <span className="section-header">6. Links</span>
                    <span className="section-content">
                        Server Tool has not reviewed all of the sites linked to its website and is
                        not responsible for the contents of any such linked site. The inclusion of
                        any link does not imply endorsement by Server Tool of the site. Use of any
                        such linked website is at the user's own risk.
                    </span>
                </span>
                <span className="section">
                    <span className="section-header">7. Modifications</span>
                    <span className="section-content">
                        Server Tool may revise these terms of service for its website at any time
                        without notice. By using this website, you are agreeing to be bound by the
                        then-current version of these terms of service.
                    </span>
                </span>
                <span className="section">
                    <span className="section-header">8. Governing Law</span>
                    <span className="section-content">
                        These terms and conditions are governed by and construed in accordance with
                        the laws of Missouri, and you irrevocably submit to the exclusive
                        jurisdiction of the courts in that State or location.
                    </span>
                </span>
            </span>
        </SimplePageContainer>
    );
};

export default TermsOfService;
