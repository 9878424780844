import styled from "styled-components";

export const SimplePageContainer = styled.div`
    ${({ theme }) => theme.container}
    border: 1px solid ${({ theme }) => theme.border};
    border-radius: 10px;
    left: 16px;
    right: 16px;
    /* top: 16px; */
    top: calc(70px + 16px);
    bottom: 16px;
    overflow-y: scroll;
    position: absolute;
    height: calc(100% - 32px - 40px - 70px);
    width: calc(100% - 32px);
    .header {
        font-size: 36px;
        font-weight: bold;
        font-family: "Inter";
        padding: 20px;
        text-align: center;
        display: block;
    }
    .content {
        font-size: 18px;
        padding: 20px;
        user-select: text;

        display: block;
        .section {
            display: block;
            padding-top: 20px;
            .section-header {
                font-size: 20px;
                font-weight: bold;
                user-select: text;
                color: ${({ theme }) => theme.text[100]};
            }
            .section-content {
                font-size: 18px;
                font-weight: 400;
                display: block;
                padding-top: 10px;
                user-select: text;
                color: ${({ theme }) => theme.text[300]};
                ul {
                    padding: 10px;
                    padding-left: 30px;
                    user-select: text;
                    color: ${({ theme }) => theme.text[300]};
                    li {
                        user-select: text;
                        font-weight: 400;
                    }
                }
            }
        }
    }
`;
