import { useQuery } from '@tanstack/react-query';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const getGuilds = async (axiosInstance) => {
  const response = await axiosInstance.get('/guilds');
  return response.data;
};

export const useGuilds = () => {
  const axiosPrivate = useAxiosPrivate();

  return useQuery({
    queryKey: ['guilds'],
    queryFn: () => getGuilds(axiosPrivate),
  });
};