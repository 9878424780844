import { useContext } from "react";
import AuthContext from "../context/AuthProvider";
import { jwtDecode } from "jwt-decode";

const useAuth = () => useContext(AuthContext);

export const useDecodedAuth = () => {
    const { auth } = useContext(AuthContext);
    if (!auth?.accessToken) return null;
    return jwtDecode(auth?.accessToken);
};

export default useAuth;
