import { axiosClient } from "../app/api/apiSlice";

export const Callback = async (code) => {
    return await axiosClient.get(
        `/discord/callback?code=${code}&cookies=${localStorage.getItem("cookies")}`,
        { withCredentials: true }
    );
};

export const RefreshToken = async () => {
    return await axiosClient.get("/discord/refresh", { withCredentials: true });
};

export const Logout = async () => {
    return await axiosClient.get("/discord/logout", { withCredentials: true });
};
