import React from "react";
import { useTranslation } from "react-i18next";
import { HomeContainer } from "../styles/HomeStyle";
import { useNavigate } from "react-router-dom";

const Home = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const invite = () => {
        var url =
            "https://discord.com/oauth2/authorize?client_id=1236000476143685762&permissions=404696067327&scope=bot+applications.commands";
        var width = 450;
        var height = 750;
        var left = 0;
        var top = 0;
        window.open(
            url,
            "_blank",
            "width=" + width + ", height=" + height + ", left=" + left + ", top=" + top
        );
    };
    // return <div>{t("home.title")}</div>;
    return (
        <HomeContainer>
            <div className="title-container">
                <span className="title">{t("home.title")}</span>
                <span className="subtitle">{t("home.subtitle")}</span>
            </div>
            <div className="button-container">
                <button onClick={invite}>Invite Bot</button>
                <button
                    className="dashboard"
                    onClick={() => navigate("/dashboard")}
                >
                    Dashboard
                </button>
            </div>
        </HomeContainer>
    );
};

export default Home;
