import { Link } from "react-router-dom";
import useLogout from "../../hooks/useLogout";
import useRefreshToken from "../../hooks/useRefreshToken";
import { useQuery } from "@tanstack/react-query";
import { getGuilds, useGuilds } from "../../services/guildService";

const Dashboard = () => {
    const logout = useLogout();
    const refresh = useRefreshToken();
    const { data, error, isError, isSuccess, isLoading } = useGuilds();

    if (isLoading) return <div>Loading...</div>;
    if (isError) return <div>Error: {error.message}</div>;

    return (
        <div>
            {data?.map((guild) => (
                <div key={guild.id}>
                    <Link to={`/dashboard/guild/${guild.id}`}>
                        {guild.name} {guild.memberCount} {guild.presenceCount}{" "}
                        {guild.isApplicationGuild ? "App" : "User"}
                    </Link>
                </div>
            ))}
        </div>
    );
};

export default Dashboard;
