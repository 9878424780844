export const lightMode = {
    body: "#FFF",
    text: "#000",
    toggleBorder: "#FFF",
    background: "#363537",
    backgroundImage: `url(\"${process.env.PUBLIC_URL}/images/background.png\")`,
};

export const darkMode = {
    body: "#363537",
    text: {
        100: "#FFFFFF",
        200: "#FAFAFA",
        300: "#d4d4d4",
        400: "#b8b8b8",
        500: "#929292",
        600: "#707070",
    },
    primary: {
        100: "#00AEFF",
    },
    content: {
        400: "#727272",
    },
    background: {
        100: "rgba(255, 255, 255, 0.1)",
    },
    container: {
        "background-color": "rgba(60, 59, 59, 15%)",
        "backdrop-filter": "blur(14px)",
    },

    border: "#7C7C7C",
    backgroundImage: `url(\"${process.env.PUBLIC_URL}/images/background.png\")`,
};
