import { useTranslation } from "react-i18next";
import { LoginContainer } from "../../styles/LoginStyle";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import useAuth from "../../hooks/useAuth";
import { Callback } from "../../services/authService";
import { baseURL } from "../../app/api/apiSlice";

const Login = ({}) => {
    const { auth, setAuth } = useAuth();

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from;

    const loginButtonRef = useRef();
    const [toastId, setToastId] = useState(null);
    const loginWindowRef = useRef(null);
    const intervalRef = useRef(null);

    const handleLogin = async () => {
        if (toastId) {
            toast.dismiss(toastId);
            setToastId(null);
        }
        const newToastId = await toast.info("Waiting for login...", { autoClose: false });

        setToastId(newToastId);
        const loginWindow = window.open(
            `${baseURL}/auth/discord`,
            "_blank",
            "width=800,height=600"
        );
        loginWindowRef.current = loginWindow;

        const checkWindowClosed = setInterval(() => {
            if (loginWindow.closed) {
                clearInterval(checkWindowClosed);
            }
        }, 1000);
    };

    const handleRedirect = async (code) => {
        try {
            const response = await Callback(code);

            if (response.status === 200) {
                const { accessToken, user } = response.data;
                await setAuth({ accessToken });
                toast.update(toastId, {
                    render: t("login.toast.success"),
                    type: "success",
                    autoClose: 2000,
                });
                navigate(from || "/dashboard", { replace: true });
            } else {
                throw new Error("Login failed");
            }
        } catch (error) {
            toast.update(toastId, {
                render: t("login.toast.error"),
                type: "error",
                autoClose: 2000,
            });
        } finally {
            if (loginWindowRef.current) {
                clearInterval(intervalRef.current);
            }
        }
    };

    useEffect(() => {
        const handleMessage = (event) => {
            if (event.origin !== window.location.origin) return;
            if (event.data.type === "discord-auth-code") {
                handleRedirect(event.data.code);
                // event.source.close();
            } else if (event.data.type === "discord-auth-error") {
                toast.update(toastId, {
                    render: t("login.toast.error"),
                    type: "error",
                    autoClose: 2000,
                });
                if (loginWindowRef.current) {
                    // loginWindowRef.current.close();
                    clearInterval(intervalRef.current);
                }
            }
        };

        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, [toastId]);

    useEffect(() => {
        console.log(auth?.accessToken);
        if (auth?.accessToken) navigate("/dashboard");
    }, []);

    return (
        <LoginContainer>
            <div className="title-container">
                <span className="discord-logo">
                    <FontAwesomeIcon icon={faDiscord} />
                </span>
                <span className="title">{t("login.title")}</span>
                <span className="subtitle">
                    {from ? t("login.redirectSubtitle") : t("login.defaultSubtitle")}
                </span>

                <button
                    ref={loginButtonRef}
                    onClick={handleLogin}
                >
                    {t("login.button")}
                </button>
            </div>
        </LoginContainer>
    );
};

export default Login;
