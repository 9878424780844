import axios from "axios";

export const baseURL =
    process.env.NODE_ENV === "development" ? "http://localhost:3120" : "https://servertool.xyz";
const headers = {
    "Content-Type": "application/json",
};

export const axiosClient = axios.create({
    baseURL: `${baseURL}/auth`,
    headers,
});

export const axiosPrivate = axios.create({
    baseURL: `${baseURL}/api`,
    headers,
    withCredentials: true,
});
