// ThemeContext.js
import React, { createContext, useState, useEffect, useContext } from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import GlobalStyles from "../styles/GlobalStyles";
import { darkMode, lightMode } from "../config/themes";
import { ToastContainer, Zoom } from "react-toastify";

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState("dark");

    const toggleTheme = () => {
        setTheme(theme === "light" ? "dark" : "light");
    };

    useEffect(() => {
        const userPrefersDark =
            window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
        if (userPrefersDark) {
            setTheme("dark");
        }
    }, []);

    return (
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
            <StyledThemeProvider theme={theme === "light" ? lightMode : darkMode}>
                <GlobalStyles />
                {children}
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme={theme === "light" ? "light" : "dark"}
                    transition={Zoom}
                />
            </StyledThemeProvider>
        </ThemeContext.Provider>
    );
};
