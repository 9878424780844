import { Link, Outlet } from "react-router-dom";
import { useTheme } from "../../context/ThemeContext";
import { LayoutContainer } from "../../styles/LayoutStyle";
import { useTranslation } from "react-i18next";
import { MaterialSymbol } from "react-material-symbols";
import { useDecodedAuth } from "../../hooks/useAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faServer, faUserGroup } from "@fortawesome/free-solid-svg-icons";

const Layout = () => {
    const { t } = useTranslation();
    const { theme } = useTheme();
    const profile = useDecodedAuth();

    const serverstatus = "online";
    const serverCount = 4;
    const userCount = 8223;

    return (
        <LayoutContainer $serverstatus={serverstatus}>
            <header>
                <ul>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="https://discord.gg/servertool">{t("layout.support")}</Link>
                    </li>
                    <li>
                        <Link to="/documentation">{t("layout.documentation")}</Link>
                    </li>
                    <li>
                        {profile ? (
                            <button onClick={() => {}}>
                                <img
                                    src={`https://cdn.discordapp.com/avatars/${profile.id}/${profile.avatar}.png`}
                                    alt="profile"
                                />
                                <MaterialSymbol
                                    icon="keyboard_arrow_down"
                                    size={30}
                                />
                            </button>
                        ) : (
                            <Link to="/login">Login</Link>
                        )}
                    </li>
                    <li>
                        <button onClick={() => {}}>
                            <MaterialSymbol
                                icon="notifications"
                                size={24}
                                fill
                            />
                        </button>
                    </li>
                </ul>
            </header>

            <div className="Outlet">
                <Outlet />
            </div>
            <footer>
                <ul>
                    <li>
                        <FontAwesomeIcon icon={faGlobe} />
                        <span>{serverstatus}</span>
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faServer} />
                        <span>{serverCount.toLocaleString()}</span>
                    </li>
                    <li>
                        <span>
                            <FontAwesomeIcon icon={faUserGroup} />
                        </span>
                        <span>{userCount.toLocaleString()}</span>
                    </li>
                    <li>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                        <Link to="/terms-of-service">Terms of Service</Link>
                    </li>
                </ul>
            </footer>
        </LayoutContainer>
    );
};

export default Layout;
