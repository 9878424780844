import styled from "styled-components";

export const LayoutContainer = styled.div`
    position: fixed;
    background-image: ${({ theme }) => theme.backgroundImage};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    header {
        height: 70px;
        border-bottom: 1px solid ${({ theme }) => theme.border};
        ${({ theme }) => theme.container}
        ul {
            height: 100%;
            list-style-type: none;
            margin: 0;
            padding: 0;
            overflow: hidden;
            li {
                float: left;

                &:nth-child(1) {
                    margin-left: 8px;
                }
                &:nth-child(4),
                &:nth-child(5) {
                    float: right;
                    margin-right: 8px;
                }
                padding: 15px 0px;
                a,
                button {
                    display: block;
                    border: none;
                    text-align: center;
                    text-decoration: none;
                    padding: 10px 16px;
                    color: ${({ theme }) => theme.text[200]};
                    background-color: transparent;
                    font-size: 16px;
                    font-family: "Inter";
                    font-weight: 500;
                    cursor: pointer;
                    border-radius: 20px;
                    transition: background-color 0.3s;

                    &:hover {
                        background-color: ${({ theme }) => theme.background[100]};
                    }
                }
                button {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    width: 40px;
                    height: 40px;
                    &:has(img) {
                        width: 70px;

                        border-radius: 20px;
                        img {
                            width: 36px;
                            height: 36px;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
    }
    .outlet {
    }
    footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 40px;
        border-top: 1px solid ${({ theme }) => theme.border};
        ${({ theme }) => theme.container}

        ul {
            height: 100%;
            list-style-type: none;
            margin: 0;
            padding: 0;
            overflow: hidden;
            li {
                float: left;

                &:nth-child(1) {
                    margin-left: 12px;
                }
                &:nth-child(4),
                &:nth-child(5) {
                    float: right;
                    &:nth-child(4) {
                        margin-right: 12px;
                    }
                    &:nth-child(5) {
                        margin-right: 16px;
                    }
                }
                text-align: center;
                display: flex;
                align-items: center;
                height: 100%;
                padding: 0 8px;
                font-size: 14px;
                font-family: "Roboto";
                font-weight: 400;
                color: ${({ theme }) => theme.primary[100]};
                a {
                    text-decoration: none;
                    color: ${({ theme }) => theme.primary[100]};
                }
                &:nth-child(1) {
                    font-size: 14px;
                    font-family: "Roboto";
                    font-weight: 400;
                    color: ${({ $serverstatus }) =>
                        $serverstatus === "online"
                            ? "rgb(0, 255, 136)"
                            : $serverstatus === "offline"
                            ? "rgb(226, 52, 52)"
                            : "rgb(240, 228, 69)"};
                }

                svg {
                    height: 16px;
                    width: 16px;
                    color: ${({ theme }) => theme.content[400]};
                }
                span {
                    padding-left: 5px;
                }
            }
        }
    }
`;
