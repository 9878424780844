import { useNavigate } from "react-router-dom";
import { Logout } from "../services/authService";
import useAuth from "./useAuth";
import { toast } from "react-toastify";

const useLogout = () => {
    const { setAuth } = useAuth();
    const navigate = useNavigate();

    const logout = async () => {
        await setAuth({});
        try {
            await Logout();
            await toast.success("Logged out successfully");
        } catch (error) {
            console.error(error);
        }
        navigate("/login");
    };
    return logout;
};

export default useLogout;
