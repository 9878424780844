import styled from "styled-components";

export const LoginContainer = styled.div`
    .title-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 82vh;
        color: white;
        .title {
            font-weight: 800;
            font-size: 50px;
        }
        .subtitle {
            font-weight: 900;
            font-size: 20px;
        }
        .title,
        .subtitle {
            font-family: "Mokoto Glitsch 2", Arial, sans-serif;
            color: white;
            text-align: center;
            padding: 10px;
            color: white;
            animation: fade-in 2s;
        }
        svg {
            padding-bottom: 20px;
            animation: fade-in-down 2s;
            color: #7289da;
            font-size: 85px;
        }
        button {
            font-size: 15px;
            margin-left: 15px;
            color: white;
            background-color: #3b5a9c;
            width: 150px;
            height: 40px;
            border-radius: 5px;
            border: None;
            transition: background-color 0.3s;
            animation: fade-in 2s;
            &:hover {
                cursor: pointer;
                background-color: #24365e;
                transition: background-color 0.3s;
            }
        }
    }
`;
