import { createGlobalStyle } from "styled-components";
import "../config/fonts/inter.css";
import "../config/fonts/roboto.css";

const GlobalStyles = createGlobalStyle`
    body {
        color: ${({ theme }) => theme.text[200]};
    }
    * {
        user-select: none;
        margin: 0;
        padding: 0;
        font-weight: bold;
        font-family: "Roboto", Arial, sans-serif;
    }
    @font-face {
        font-family: "Mokoto Glitsch 2";
        src: url("../config/fonts/mokoto-mokoto-regular-glitch-mark-2-400.ttf") format("truetype");
    }

    @font-face {
        font-family: "Lato-Light";
        src: url("../config/public/fonts/Lato-Light.ttf") format("truetype");
    }
    @keyframes fade-in {
        from {
            opacity: 0;
            transform: translateY(20px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes fade-in-down {
        from {
            opacity: 0;
            transform: translateY(-20px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
`;

export default GlobalStyles;
