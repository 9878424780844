import React from "react";
import { SimplePageContainer } from "../../styles/simplePageStyle";

const PrivacyPolicy = () => {
    return (
        <SimplePageContainer>
            <span className="header">Privacy Policy</span>
            <span className="content">
                <span className="section">
                    <span className="section-content">
                        It is Server Tool's policy to respect your privacy regarding any information
                        we may collect from you across our website, https://servertool.xyz, and our
                        Discord app, as well as other sites we own and operate.
                    </span>
                </span>
                <span className="section">
                    <span className="section-header">Information We Collect</span>
                </span>
                <span className="section">
                    <span className="section-header">Log Data</span>
                    <span className="section-content">
                        When you visit our website, our servers may
                        automatically log the standard data provided by your web browser.
                        This data is considered "non-identifying information" as it does not
                        personally identify you on its own. It may include:
                        <ul>
                            <li>Your computer's Internet Protocol (IP) address</li>
                            <li>Your browser type and version</li>
                            <li>The pages you visit</li>
                            <li>The time and date of your visit</li>
                            <li>The time spent on each page</li>
                            <li>Other details about your visit</li>
                        </ul>
                    </span>
                </span>
                <span className="section">
                    <span className="section-header">Discord Data</span>
                    <span className="section-content">
                        When you interact with our Discord app, we may collect certain data from
                        your Discord profile and activity within the app. This may include:
                        <ul>
                            <li>Your Discord user ID and username</li>
                            <li>Your server roles and permissions</li>
                            <li>Messages or commands you send to the app</li>
                            <li>Other interactions within the app</li>
                        </ul>
                    </span>
                </span>
                <span className="section">
                    <span className="section-header">How We Collect Information</span>
                    <span className="section-content">
                        We collect information by fair and lawful means, with your knowledge and
                        consent. We also inform you about why we’re collecting it and how it will be
                        used. You are free to refuse our request for this information, understanding
                        that we may be unable to provide some of your desired services without it.
                    </span>
                </span>
                <span className="section">
                    <span className="section-header">Use of Information</span>
                    <span className="section-content">
                        We use a combination of identifying and non-identifying information to:
                        <ul>
                            <li>Understand who our users are</li>
                            <li>Analyze how users engage with our services</li>
                            <li>
                                Improve the user experience on our website and within our Discord
                                app
                            </li>
                        </ul>
                        We do not disclose the specifics of this information publicly but may share
                        aggregated and anonymized versions of this information, for example, in
                        usage trend reports.
                    </span>
                </span>
                <span className="section">
                    <span className="section-header">Data Processing and Storage</span>
                    <span className="section-content">
                        We only retain personal information for as long as necessary to provide our
                        services or improve them in the future. While retaining this data, we
                        protect it using commercially acceptable measures to prevent loss, theft,
                        unauthorized access, disclosure, copying, use, or modification. However, no
                        method of electronic transmission or storage is 100% secure, and we cannot
                        guarantee absolute data security.
                    </span>
                </span>
                <span className="section">
                    <span className="section-header">Cookies</span>
                    <span className="section-content">
                        We use "cookies" to collect information about your activity across our site.
                        A cookie is a small piece of data that our website stores on your computer
                        and accesses each time you visit to help us understand how you use our site
                        and serve you content based on your preferences.
                    </span>
                    <span className="section-content">
                        If you do not wish to accept cookies from us, instruct your browser to
                        refuse cookies from our website. Note that we may be unable to provide some
                        services without cookies. This policy covers only our use of cookies and
                        does not cover the use of cookies by any third-party services we utilize.
                    </span>
                </span>
                <span className="section">
                    <span className="section-header">Children's Privacy</span>
                    <span className="section-content">
                        Our website and Discord app do not knowingly target or collect personal
                        information from children. If you are a parent or guardian and believe your
                        child has provided personal information on our platforms, please contact us.
                        We do not use your supplied contact details for marketing or promotional
                        purposes.
                    </span>
                </span>
                <span className="section">
                    <span className="section-header">Limits of Our Policy</span>
                    <span className="section-content">
                        This privacy policy only covers Server Tool's own data collection and
                        handling practices. We only work with partners, affiliates, and third-party
                        providers whose privacy policies align with ours. However, we cannot accept
                        responsibility or liability for their respective privacy practices.
                    </span>
                    <span className="section-content">
                        Our website and Discord app may link to external sites not operated by us.
                        We have no control over the content and policies of those sites and cannot
                        accept responsibility or liability for their privacy practices.
                    </span>
                </span>
                <span className="section">
                    <span className="section-header">Changes to This Policy</span>
                    <span className="section-content">
                        At our discretion, we may update this policy to reflect current acceptable
                        practices. We will take reasonable steps to inform users about changes via
                        our website. Continued use of this site or our Discord app after any changes
                        to this policy will be considered acceptance of our practices around data
                        and personal information.
                    </span>
                </span>
                <span className="section">
                    <span className="section-header">Your Rights and Responsibilities</span>
                    <span className="section-content">
                        As a user, you have the right to be informed about how your data is
                        collected and used. You are entitled to:
                        <ul>
                            <li>Know what data we collect about you</li>
                            <li>Understand how it is processed</li>
                            <li>Correct and update your personal information</li>
                            <li>Request the deletion of your personal information</li>
                        </ul>
                        You have the right to restrict or object to our use of your data while
                        retaining the right to use your personal information for your purposes. You
                        may also opt-out of having your data used in decisions based solely on
                        automated processing.
                    </span>
                    <span className="section-content">
                        Feel free to contact us if you have any concerns or questions about how we
                        handle your data and personal information.
                    </span>
                </span>
            </span>
        </SimplePageContainer>
    );
};

export default PrivacyPolicy;