import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/login/Login";
import DiscordRedirect from "./pages/login/DiscordRedirect";
import Dashboard from "./pages/dashboard/Dashboard";
import RequireAuth from "./components/RequireAuth";
import Unauthorized from "./pages/dashboard/Unauthorized";
import PersistLogin from "./components/PersistLogin";
import Layout from "./pages/dashboard/Layout";
import "react-material-symbols/rounded";
import TermsOfService from "./pages/public/TermsOfService";
import PrivacyPolicy from "./pages/public/PrivacyPolicy";
import Test from "./pages/Test";

function App() {
    return (
        <Routes>
            <Route element={<PersistLogin />}>
                <Route
                    path="/"
                    element={<Layout />}
                >
                    <Route
                        index
                        element={<Home />}
                    />
                    <Route
                        path="/terms-of-service"
                        element={<TermsOfService />}
                    />
                    <Route
                        path="/privacy-policy"
                        element={<PrivacyPolicy />}
                    />
                    <Route
                        path="/login"
                        element={<Login />}
                    />
                    <Route
                        path="/discord-redirect"
                        element={<DiscordRedirect />}
                    />
                    <Route
                        path="/unauthorized"
                        element={<Unauthorized />}
                    />
                    <Route element={<RequireAuth allowedPermissions={["Authenticated"]} />}>
                        <Route
                            path="/dashboard"
                            element={<Dashboard />}
                        />
                        <Route
                            path="/test"
                            element={<Test />}
                        />
                    </Route>
                </Route>
            </Route>
        </Routes>
    );
}

export default App;
